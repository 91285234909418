<template>
  <div>
    <v-data-table v-if="!isLoading" :headers="headers" :items="companies">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" class="mr-3" v-on="on" @click="editCompany(item)"> mdi-pencil </v-icon>
          </template>
          Módosítás
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" v-on="on" @click="openDeleteConfirmator(item)"> mdi-delete </v-icon>
          </template>
          Törlés
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              size="20"
              color="black"
              class="ml-3"
              v-on="on"
              @click="$router.push({ name: 'company', params: { id: item.id } })"
            >
              mdi-cog
            </v-icon>
          </template>
          Beállítások
        </v-tooltip>
      </template>
    </v-data-table>

    <v-skeleton-loader type="table" v-else></v-skeleton-loader>
    <ConfirmDialog :confirm-handler="deleteConfirmHandler"></ConfirmDialog>
    <CompanyDialog></CompanyDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { DELETE_COMPANY } from '@/store/types'

const ConfirmDialog = () => import('@/components/Global/ConfirmDialog.vue')
const CompanyDialog = () => import('@/components/Companies/CompanyDialog.vue')

export default {
  name: 'CompaniesTable',
  components: {
    ConfirmDialog,
    CompanyDialog
  },
  data() {
    return {
      headers: [
        {
          text: '#',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Domain',
          align: 'start',
          value: 'domain'
        },
        {
          text: 'Név',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Csomag',
          align: 'start',
          value: 'plan.name'
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      deleteConfirmHandler: {}
    }
  },
  computed: {
    ...mapState({
      status: state => state.companies.status,
      companies: state => state.companies.companies
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  methods: {
    openDeleteConfirmator(company) {
      this.deleteConfirmHandler = {
        handler: () => this.deleteCompany(company),
        title: `${company.name} cég törlése`
      }
      this.$modal.show('confirm-dialog')
    },
    async deleteCompany(company) {
      await this.$store.dispatch(DELETE_COMPANY, company.id)
      this.$modal.hide('confirm-dialog')
    },
    editCompany(company) {
      this.$modal.show('company-dialog', {
        company,
        isNewCompany: false
      })
    }
  }
}
</script>
