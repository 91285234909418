<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="isLoading">
          <v-card-title>Cégek</v-card-title>
          <v-card-text>
            <CompaniesTable />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import { GET_COMPANIES_REQUEST } from '@/store/types'

import CompaniesTable from '@/components/Companies/CompaniesTable.vue'

export default {
  name: 'PageCompanies',
  components: {
    CompaniesTable
  },
  computed: {
    ...mapState({
      status: state => state.companies.status
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  created() {
    this.$store.dispatch(GET_COMPANIES_REQUEST)
  }
}
</script>
